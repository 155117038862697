<template>
  <div>
    <el-dialog :close-on-click-modal='false' custom-class="custom-dialog" :append-to-body="true" :modal-append-to-body="true" :visible.sync="dialogVisible" :width="element.options.inputBtnWidth" :title="element.options.inputBtnTitle" top="10vh">
        <div class="popup-content1">
            <el-alert v-show="isShowWarning" title="两次密码输入不一致" type="warning" show-icon />
            <el-form ref="repwsInfoform" :model="repwsInfo" label-position="top">
                <el-form-item  prop="USERNAME" label="用户名">
                    <el-input v-model="userInfo.USERNAME" disabled></el-input>
                </el-form-item>
                
                <!-- <el-form-item label="旧密码" prop="OLDPASSWORD" :rules="[{ message: '必须填写', required: true, trigger: 'change'}]">
                    <el-input  v-model="repwsInfo.OLDPASSWORD"  placeholder='请输入旧密码' show-password></el-input>
                </el-form-item> -->
                <el-form-item label="新密码" prop="NEWPASSWORD" :rules="[{ message: '必须填写', required: true, trigger: 'change'}]">
                    <el-input  v-model="repwsInfo.NEWPASSWORD"  placeholder='请输入新密码' show-password></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="CHECKPASSWORD" :rules="[{ message: '必须填写', required: true, trigger: 'change'}]">
                    <el-input  v-model="repwsInfo.CHECKPASSWORD" placeholder='请确认密码' show-password></el-input>
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="determineButton">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
import Utils from "../../../../utils";
export default {
  name: 'customization_index',
  props: ['currentCustomEventInfo'],
  data() {
    return {
      CanDeleteFlag:true, // 能否删除的标记
      dialogVisible: false,
        element: {
            options: {
                inputBtnWidth: '600px',
                inputBtnTitle: '修改密码'
            }
        },

        isShowWarning: false,
        repwsInfo:{
            OLDPASSWORD:"",
            NEWPASSWORD:"",//新密码
            CHECKPASSWORD:"",//确认密码
        },// 修改密码的信息
        userInfo: {},
    }
  },
  components: {
    toStock: () => import('@/views/customizationPage/customization/pwdDialog'),//弹出框客户化参考
  },
  computed: {
    customEventInfo() {
      return this.currentCustomEventInfo
    },
    app() {
      var str = this.$route.params.pathMatch
      // generalApp/WZTZITEM
      var arr = str.split('/')
      return arr[1]
    },
    //当前应用信息
    currentAppInfo() {
      return this.customEventInfo[this.app]
    },
    //主表信息
    detailInfos() {
      return this.currentAppInfo.detailInfos
    },
    // 子表信息
    childTableJson() {
      return this.currentAppInfo.childTableJson
    },
    // 登录人信息
    currentuserinfo(){
      return this.$store.state.currentuserinfo
    }
  },
  beforeDestroy() {

  },
  watch: {

  },
  methods: {
    /**
     * 计算下次执行时间
     * @param {*} num 计数
     * @param {*} unit 单位
     * @param {*} valueAttr 日期时间字段 
     */
     setDateTime(num,unit,valueAttr) {
      var valueDate = this.$moment().format('YYYY-MM-DD HH:mm:ss')
      var unit = unit
      if(!unit) {
        var unit = 'D'
      }

      if(unit == 'D') {
        valueDate = this.$moment().add(num, 'd').format('YYYY-MM-DD HH:mm:ss')
      }
      if(unit == 'W') {
        valueDate = this.$moment().add(num,'w').format('YYYY-MM-DD HH:mm:ss')
      }
      if(unit == 'M') {
        valueDate = this.$moment().add(num,'M').format('YYYY-MM-DD HH:mm:ss')
      }
      if(unit == 'Y') {
        valueDate = this.$moment().add(num,'y').format('YYYY-MM-DD HH:mm:ss')
      }
      // 下次执行时间
      this.detailInfos[valueAttr] = valueDate
      this.updateDetailInfos()

    },
    // 预防性维护 详情页  根据 检查周期单位 检查周期 计算 下次执行时间
    setpmNexttime(item) {
      var {FREQUENCY,FREQUENCYUNIT} = this.detailInfos
      this.setDateTime(FREQUENCY,FREQUENCYUNIT,'NEXTEXECUTETIME')
    },
    // 预防性维护-预防性维护 '生成PM工单'按钮
    PmCreateWo(item) {
      const url = Config.dev_url + '/api-apps/pm/PmCreateWorkorder';


      var obj = {}
      var attrs = Object.keys(this.detailInfos)

      attrs.forEach(attr=>{
        var lowercaseName = attr.toLowerCase()
        obj[lowercaseName] = this.detailInfos[attr]
      })

      var data = { PmInfo: obj }
      this.$axios.post(url, data).then((res) => {
          if (res.data.code == 0) {
            this.$message.success(res.data.msg)
          } else {
            this.$message.warning(res.data.msg)
          }
        })
        .catch((err) => {})
    },

    // 工单管理-工单管理  作业标准编号 弹出框确定 按钮
    insertZybz(item) {
      // singleChoiceData：弹出框选中的数据
      var { singleChoiceData } = this.currentCustomEventInfo
      this.detailInfos.insertZybz = {
        insaenum: singleChoiceData.JPNUM
      }
      this.updateDetailInfos()
    },
    //insertZybz 清空操作
    ClearinsertZybz(item) {
      delete this.detailInfos.insertZybz;
      this.updateDetailInfos()
      this.customEventInfo.done();//inputBtn组件清空
    },
    // 工单管理保存 insertZybz详情保存并查看
    insertZybzSave() {
      // saveBtn(str)
      // str  '': "通用保存",'after': "仅保存并返回接口数据",
      // viewBtn(item)
      // item:  "主详情数据"
      var {saveBtn,viewBtn} = this.customEventInfo.methods
      var _this = this;
      saveBtn('after').then(detail=>{
        if(!_this.detailInfos.insertZybz) {
          viewBtn(detail)
          return
        }
        const url = Config.dev_url + '/api-apps/masterdata/InsertPmTask'
        var data = {
          insaenum: _this.detailInfos.insertZybz.insaenum,
          wonum: detail.WONUM
        }
        _this.$axios.post(url, data).then((res) => {
            if (res.data.code == 0) {
              _this.$message.success(res.data.msg)
            } else {
              _this.$message.warning(res.data.msg)
            }
          }).finally(()=>{
            viewBtn(detail)
          })
      })
    },
    insertdbmbSave() {
      
      // saveBtn(str)
      // str  '': "通用保存",'after': "仅保存并返回接口数据",
      // viewBtn(item)
      // item:  "主详情数据"
      var {saveBtn,viewBtn} = this.customEventInfo.methods
      var _this = this;
      saveBtn('after').then(detail=>{
        console.log(_this.detailInfos)
        console.log(1231)
        if(_this.detailInfos.TEMPNUM&&_this.detailInfos.TableUKey53740.length != 0) {
          viewBtn(detail)
          return
        }
        if(_this.detailInfos.TEMPNUM&&_this.detailInfos.TableUKey53740.length == 0){

        // const url ='http://192.168.1.129:7902/masterdata/InsertDB'
        const url = Config.dev_url + '/api-apps/masterdata/InsertDB'
        var data = {
          insaenum: _this.detailInfos.TEMPNUM,
          wonum: detail.ASSETNUMBER,
          offset:_this.detailInfos.OFFSETADDRESS
        }
        _this.$axios.post(url, data).then((res) => {
            if (res.data.code == 0) {
              _this.$message.success(res.data.msg)
            } else {
              _this.$message.warning(res.data.msg)
            }
          }).finally(()=>{
            viewBtn(detail)
          })
        }
      })
    },
    show(item) {
            /**
             * mainTableSelectData 主列表选中的数据
             */
            var app = this.$route.params.app;
            var {mainTableSelectData} = this.currentAppInfo;
            console.log(mainTableSelectData)
            if(mainTableSelectData.length == 0){
              this.$message.warning('请选择要修改的用户');
              return
            }
            this.userInfo = mainTableSelectData[0]
            this.dialogVisible = true
            this.$nextTick(_=>{
                console.log(this.$refs)
                this.$refs.repwsInfoform.resetFields();
            })
        },
    determineButton() {
        this.isShowWarning = false;
        // this.repwsInfo.OLDPASSWORD = this.userInfo.PASSWORD
        this.$refs.repwsInfoform.validate((valid)=>{
            if(valid){
                var {NEWPASSWORD,CHECKPASSWORD,OLDPASSWORD} = this.repwsInfo;

                if(NEWPASSWORD == CHECKPASSWORD) {//修改密码
                    this.isShowWarning = false;
                    const url = Config.dev_url + '/api-apps/mobile/users/modify/password';
                    this.$axios.put(url, {
                        ID: this.userInfo['ID'],
                        // OLDPASSWORD: Utils.encrypt(OLDPASSWORD,"hAw6eqnFLKxpsDv3"),
                        NEWPASSWORD: NEWPASSWORD,
                    }).then((res) => {
                        if (res.data.code == 0) {//code == 0是后台返回的请求成功的信息
                            this.$message.success('修改成功');
                            setTimeout(() => {
                                this.isShowWarning = false;
                                this.dialogVisible = false
                            }, 500);

                        } else {
                        this.$message.warning(res.data.msg);
                        }
                    });
                } else {
                    this.isShowWarning = true;
                }
            }
        })
    },
    //同步点表
    synchronization() {
      // if(this.detailInfos.MACHINESTATUS !="在线"){
      //   this.$message.success('设备不在线，无法同步')
      //   return
      // }
      let loading = this.$loading({
          lock: true,
          text: '同步点表中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      var _this = this;
      console.log(_this.detailInfos)
      const url = Config.dev_url + '/api-apps/masterdata/iot/edge/update'
      var data = {
        id: '',
        type: "update",
      }
      if(_this.detailInfos.EUPMENTSERIALSERVERID){
        data.id = _this.detailInfos.EUPMENTSERIALSERVERID
        data.objectname = 'EUPMENTSERIALSERVER'
      }else{
        data.id = _this.detailInfos.ASSETNUMBER
        data.objectname = 'EQUIPMENTEL'
      }
        _this.$axios.post(url, data).then((res) => {
            loading.hide();
            if (res.data.code == 0) {
              _this.$message.success(res.data.msg)
            } else {
              _this.$message.warning(res.data.msg)
            }
          }).catch((err)=>{
            loading.hide();
            _this.$message.warning(err)
          }).finally(()=>{
            loading.hide();
          })
    },
    //跳转新建工单
    openworkroder(item) {
      
      console.log(item);
      let that = this;
      
        console.log(encodeURI(item.bizappdesc));
        let routeData = this.$router.resolve({
          path: "/productGeneral/generalApp/WORKORDER",
          query: {
            type: "todo",
            gotoaddpage:true
          },
        }); 
        layer.open({
          type: 2,
          area: ["90%", "90%"],
          closeBtn: 1,
          title: '新建工单',
          content: routeData.href,
          // end: function () {
          //   that.getNeeddoList("init");
          //   that.getAlreadydoList("init");
          //   that.getallTaskCounts();
          //   that.getremainingTaskCounts();
          //   that.gettodaysTaskCounts();
          //   that.gettaskCompletionRate();
          // },
        }); 
    },
    typechenge(){
      // alert(1)
      this.$set(this.detailInfos,'MACHINE_TYPE',"")
      this.detailInfos.MACHINE_TYPE = ""
      this.updateDetailInfos()
    },
    //修改密码
    changePwdBtn(){
      this.show()
    },
    /** ----------------------方法封装--------------------------------------- **/

    /**
     * 通过ID查询应用详情
     * @param {*} ID 
     * this.detailInfos.ID
     * @return {*}
     */
    findDetailByID: function(ID){
        return new Promise((resolve, reject)=>{
            let url = Config.dev_url + "/api-apps/app/" + this.app + "?id=" + ID;
            this.$axios.get(url,{}).then((res)=>{
                resolve(res.data);
            })
        })
    },

    // 子表前端删除
    childTableRemove: function(childmodel) {
      //oper  为状态  1：新增 2修改 3删除
      if(!this.detailInfos[childmodel]) {
        this.detailInfos[childmodel] = []
      }
      var childList = this.detailInfos[childmodel].filter(item=>item.oper !='1')
      childList.forEach(item=>{
        item.oper = "3"
      })
      this.detailInfos[childmodel] = childList;
      this.detailInfos.childChangeData[childmodel] = this.detailInfos[childmodel].filter(item=>!!item.oper)
    },
    // 详情保存
    saveBtn(item) {
      this.currentAppInfo.isSaveAllData = true;//保存数据
      this.updateDetailInfos()
      this.$nextTick(_=>{
        setTimeout(() => {
          this.currentAppInfo.isSaveAllData = false
          this.currentAppInfo.updateDetailData = true//刷新详情数据
          this.updateDetailInfos()
        }, 100);

      })
    },
      //新建默认值处理
    initAddDefaultValue(jsonData,dataSource,str){
          let defalutObj = {}
          jsonData.forEach((item)=>{
              if(str == "resetSwitchSearch"){
                  if(item.type == "switch"){
                      defalutObj[item.model] = '0'
                  }
              }else{
                  if (!!item.options.defaultValue){
                      //进度条默认值处理
                      if((item.type == "progress" || item.type == "slider" || item.type == "steps") && typeof(item.options.defaultValue) == "number"){
                          defalutObj[item.model] = item.options.defaultValue
                      }else if(item.options.defaultValue.indexOf(':U.') !== -1){
                          defalutObj[item.model] = this.currentuserinfo[item.options.defaultValue.substr(item.options.defaultValue.indexOf(':U.') + 3, item.options.defaultValue.length)]
                      }else if(item.options.defaultValue.indexOf(':M.') !== -1){
                          defalutObj[item.model] = this.detailInfos[item.options.defaultValue.substr(item.options.defaultValue.indexOf(':M.') + 3, item.options.defaultValue.length)]
                      }else if(item.options.defaultValue.indexOf(':P.') !== -1){
                          defalutObj[item.model] = dataSource['parentInfo'][item.options.defaultValue.substr(item.options.defaultValue.indexOf(':P.') + 3, item.options.defaultValue.length)]
                      } else if(item.options.defaultValue.indexOf(':N.DATETIME') !== -1) {
                          defalutObj[item.model] = this.$moment().format('YYYY-MM-DD HH:mm:ss')
                      } else if(item.options.defaultValue.indexOf(':N.DATE') !== -1) {
                          defalutObj[item.model] = this.$moment().format('YYYY-MM-DD')
                      } else if(item.options.defaultValue.indexOf(':N.TIME') !== -1) {
                          defalutObj[item.model] = this.$moment().format('HH:mm:ss')
                      }else if(item.type == "checkbox"){
                          defalutObj[item.model] = ""
                      }else{
                          defalutObj[item.model] = item.options.defaultValue
                      }
                  }else if(item.type == "input" && item.options.type == 'number'){
                      defalutObj[item.model] = undefined
                  }else if(item.type == "switch"){
                      defalutObj[item.model] = '0'
                  }else{
                      defalutObj[item.model] = "";
                  }
              }
          })
          return  defalutObj
    },
    // 通过标识获取子表json数据
    findChildTableJson: function(model) {
      var _index = this.childTableJson.findIndex(function(item){
        return item.model === model;
      });
      var result = {
        detailItemcolumns: []
      };
      if(_index>-1) {
        result = this.childTableJson[_index]
      }
      return result
    },
      //接收批量选择回来的数据进行渲染
    sendChildBatchChooseData: function(selectDta,childmodel){
      console.log(this.findChildTableJson(childmodel),"this.findChildTableJson(childmodel)")
      return new Promise((resolve) => {
        var count = selectDta.length;
        var app = this.app;
        var  url = Config.dev_url +  "/api-apps/app/" + app + "/defaultData/" + childmodel + "?counts=" + count;
        this.$axios.post(url,{
            M: this.currentAppInfo.detailInfos
        }).then((res)=>{
          if(res.data.code == 0){
              var newSelectData = selectDta.map((item,index)=>{
                  var addObj = {
                    ...this.initAddDefaultValue(this.findChildTableJson(childmodel).detailItemcolumns,{}),
                      ...res.data.data,
                      ...item,
                      ID: res.data.data.ID[index],
                      oper: '1',//oper  为状态  1：新增 2修改 3删除
                      
                  }
                  return addObj
              })
              resolve(newSelectData)
            }else{
                this.$message.warning(res.data.msg)
            }
          }).catch((err) => {});
        }).catch((err) => {})
    },
    //更新应用数据
    updateDetailInfos: function () {
      this.$actions.setGlobalState({
        customEventInfo: {
          [this.app]: this.currentAppInfo,
        }
      });
    },
    //事件生成工单
    eventGenerateWorkorder: function () {
      var _this = this;
      console.log(_this.detailInfos)
      if(!_this){
         _this.$message.success('请先保存')
         return
      }
      
      if(!_this.detailInfos.STATIONNAME){
         _this.$message.success('请先保存站点')
         return
      }
      if(!_this.detailInfos.ASSETNAME){
         _this.$message.success('请先选择设备')
         return
      }
      if(!_this.detailInfos.FIXNAME){
         _this.$message.success('请先选择运维工程师')
         return
      }
      const url = Config.dev_url + '/api-apps/WorkOrderList/generate/workorder'
        var data = {
          ID: _this.detailInfos.ID,
        }
        _this.$axios.post(url, data).then((res) => {
            if (res.data.code == 0) {
              _this.$message.success(res.data.msg)
            } else {
              _this.$message.warning(res.data.msg)
            }
          }).finally(()=>{
            
          })
    },
    eventinforClose: function () {
      var _this = this;
      
      const url = Config.dev_url + '/api-apps/WorkOrderList/close/event'
        var data = {
          ID: _this.detailInfos.ID,
        }
        _this.$axios.post(url, data).then((res) => {
            if (res.data.code == 0) {
              _this.$message.success(res.data.msg)
            } else {
              _this.$message.warning(res.data.msg)
            }
          }).finally(()=>{
            
          })
    },
  },
  mounted() {
    let that = this
    // setTimeout(() => {
    //   console.log(that.$refs)
    //   that.$refs.toStock.show()
    // }, 1000);
    
  }
}
</script>
<style lang="less" scoped>

</style>
